.root {
  font-family: "Material Symbols Rounded";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}

.xxs,
.smallest {
  font-size: 14px;
}

.xs,
.smaller {
  font-size: 16px;
}

.s,
.sm,
.small {
  font-size: 18px;
}

.m,
.md,
.medium {
  font-size: 20px;
}

.l,
.lg,
.large {
  font-size: 24px;
}

.xl,
.larger {
  font-size: 36px;
}

.xxl,
.largest {
  font-size: 48px;
}

.filled {
  font-variation-settings: "FILL" 1;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvb2xlZy9kZXYvc2F5eWVzL3NyYy9zaGFyZWQvY3VzdG9tLWNvbXBvbmVudHMvaWNvbiIsInNvdXJjZXMiOlsiSWNvbi5tb2R1bGUuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdKO0FBQUE7RUFFSTs7O0FBR0o7QUFBQTtFQUVJOzs7QUFHSjtBQUFBO0FBQUE7RUFHSTs7O0FBR0o7QUFBQTtBQUFBO0VBR0k7OztBQUdKO0FBQUE7QUFBQTtFQUdJOzs7QUFHSjtBQUFBO0VBRUk7OztBQUdKO0FBQUE7RUFFSTs7O0FBR0o7RUFDSSIsInNvdXJjZXNDb250ZW50IjpbIi5yb290IHtcbiAgICBmb250LWZhbWlseTogJ01hdGVyaWFsIFN5bWJvbHMgUm91bmRlZCc7XG4gICAgZm9udC13ZWlnaHQ6IG5vcm1hbDtcbiAgICBmb250LXN0eWxlOiBub3JtYWw7XG4gICAgZm9udC1zaXplOiAyNHB4OyAgLyogUHJlZmVycmVkIGljb24gc2l6ZSAqL1xuICAgIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgICBsaW5lLWhlaWdodDogMTtcbiAgICB0ZXh0LXRyYW5zZm9ybTogbm9uZTtcbiAgICBsZXR0ZXItc3BhY2luZzogbm9ybWFsO1xuICAgIHdvcmQtd3JhcDogbm9ybWFsO1xuICAgIHdoaXRlLXNwYWNlOiBub3dyYXA7XG4gICAgZGlyZWN0aW9uOiBsdHI7XG59XG5cbi54eHMsXG4uc21hbGxlc3Qge1xuICAgIGZvbnQtc2l6ZTogMTRweDtcbn1cblxuLnhzLFxuLnNtYWxsZXIge1xuICAgIGZvbnQtc2l6ZTogMTZweDtcbn1cblxuLnMsXG4uc20sXG4uc21hbGwge1xuICAgIGZvbnQtc2l6ZTogMThweDtcbn1cblxuLm0sXG4ubWQsXG4ubWVkaXVtIHtcbiAgICBmb250LXNpemU6IDIwcHg7XG59XG5cbi5sLFxuLmxnLFxuLmxhcmdlIHtcbiAgICBmb250LXNpemU6IDI0cHg7XG59XG5cbi54bCxcbi5sYXJnZXIge1xuICAgIGZvbnQtc2l6ZTogMzZweDtcbn1cblxuLnh4bCxcbi5sYXJnZXN0IHtcbiAgICBmb250LXNpemU6IDQ4cHg7XG59XG5cbi5maWxsZWQge1xuICAgIGZvbnQtdmFyaWF0aW9uLXNldHRpbmdzOiAnRklMTCcgMTtcbn0iXX0= */